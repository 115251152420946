@font-face {
  font-family: "apersu";
  src: local("apersuFont.woff"), url(./fonts/apersuFont.woff) format("woff");
}
@font-face {
  font-family: "apersuBold";
  src: local("apersuFontBold.woff"),
    url(./fonts/apersuFontBold.woff) format("woff");
}
@font-face {
  font-family: "apersuLogo";
  src: local("apersuFontLogo.woff"),
    url(./fonts/apersuFontLogo.woff) format("woff");
}
h1 {
  font-size: calc(10px + 1.7vw);
}
h2 {
  font-size: calc(8px + 1.7vw);
}
.App {
  text-align: center;
  font-family: "apersu";
}
b {
  font-family: "apersuBold";
}
.App .ae-logo {
  font-size: calc(22px + 3vw);
  font-family: "apersuLogo";
}
.App .footerlogo {
  font-size: calc(11px + 0.2vw);
  font-family: "apersuLogo";
}
.App .navButtons {
  font-size: calc(7px + 0.5vw);
}
.App .pageTitle {
  font-size: calc(15px + 0.6vw);
}
.App .pageTitleSm {
  font-size: calc(15px + 1.5vw);
}
.App .pageSubheading {
  font-size: calc(15px + 2vw);
}
.App .pageSubheadingSm {
  font-size: calc(15px + 3vw);
}
.App .tableHeader {
  font-size: calc(8px + 0.5vw);
}
.App .tableBody {
  font-size: calc(12px + 0.5vw);
}
.App .companyTitle {
  font-size: calc(15px + 1.5vw);
}
.App .cardText {
  font-size: calc(12px + 0.5vw);
}
.App .cardTitle {
  font-size: calc(10px + 0.9vw);
}
.App .table {
  font-size: calc(8px + 0.6vw);
}
.App .tab {
  font-size: calc(7px + 0.8vw);
}
.App .tabTitle {
  font-size: calc(0.7em + 0.1vw);
}
.App .materialCost {
  font-size: calc(8px + 1.2vw);
}
.App .materialCostsm {
  font-size: calc(10px + 2.1vw);
}
.App .pageSubtitle {
  font-size: calc(8px + 1vw);
}
.App .estimateDetailTitle {
  font-size: calc(10px + 1.1vw);
}
.App .estimateDetailTitleSm {
  font-size: calc(13px + 1.3vw);
}
.App .estimateDetailBody {
  font-size: calc(7px + 1.1vw);
}
.App .estimateDetailBodySm {
  font-size: calc(12px + 1.1vw);
}
.App .noEstimatesErrorText {
  color: red;
}
.App .blueLink {
  color: rgb(2, 32, 121);
}
.App .cardImageDiv {
  max-height: 400px;
}
.App .cardImage {
  width: 100%;
  height: 100%;
}
.App .cardLogoDiv {
  max-height: 300px;
}
.App .formInputError {
  color: crimson;
}
.App .estimateSummarySubtitle {
  font-size: calc(8px + 1vw);
}
.App .estimateSummaryContent {
  font-size: calc(7px + 0.9vw);
}

.App .userAvatar {
  height: 4rem /* 48px */;
  width: 4rem /* 48px */;
  border-radius: 9999px;
  padding: 0px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
* {
  box-sizing: border-box;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safari-specific-class {
      /* Add your Safari-specific CSS here */
      display: flex;
      flex-direction: column;
    }
  }
}
* {
  box-sizing: border-box;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* .App .container {
  flex-grow: 1;
  padding: 0 60px;
} */
